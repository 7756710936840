import Home from "./Pages/Home/Home";
import {
  createBrowserRouter,
  Route,
  Outlet,
  Routes,
} from "react-router-dom";

import {QueryClientProvider, QueryClient} from 'react-query'
import LanguageProvider from "./Contexts/LanguageContext";
import Footer from "./Components/Footer/footer";
import Header from "./Components/Header/Header";
import Courses from "./Pages/Courses/Courses";
import CourseCategories from "./Pages/CourseCategories/CourseCategories";
import RegistrationPage from "./Pages/RegistrationPage/RegistrationPage";
import Course from "./Pages/Course/Course";
import './App.css'
const Layout = () => {
  return (
    <>
      <Header displaySearch={false} />
      {/* by defaut displaySearch is false */}
      <Outlet />
      <Footer />
    </>
  );
};
const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,

    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/courses/:id",
        element: <Courses />,
      },
      {
        path: "/course/:id",
        element: <Course />,
      },
      {
        path: "/categories",
        element: <CourseCategories />,
      },
      {
        path: "/register/:id",
        element: <RegistrationPage />,
      },
      {
        path: "/*",
        element: (
          <div className="pageNotFound">
            <p>Page not found</p>
          </div>
        ),
      },
    ],
  },
]);
function App() {
  // return <Home />;
  {
    /* <RouterProvider router={router} /> */
    /* The above was cleaner(using createBrowserRouter), but was not easy to use scrollToTop used in index.js. 
    https://www.matthewhoelter.com/2022/04/02/how-to-scroll-to-top-on-route-change-with-react-router-dom-v6.html

    https://stackoverflow.com/questions/66747556/react-js-error-uselocation-may-be-used-only-in-the-context-of-a-router-com
    */
  }
  return (
    <LanguageProvider>
    <QueryClientProvider client={new QueryClient()}>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="/courses/:id" element={<Courses />} />
        <Route path="/course/:id" element={<Course />} />
        <Route path="/categories" element={<CourseCategories />} />
        <Route path="/register/:id" element={<RegistrationPage />} />
        <Route
          path="*"
          element={
            <div className="pageNotFound">
              <p>Page not found</p>
            </div>
          }
        />
      </Route>
    </Routes>
    </QueryClientProvider>
    </LanguageProvider>
  );
}

export default App;
