import React from "react";
import tiktok from "../../Assets/images/tiktok48x48.png";
import styles from "./footer.module.css";


const Footer = () => {
  return (
    <footer className={styles.footer}>
     <div className={styles.socialMedia}>
     {/* <img src={tiktok} alt="tiktok" /> */}
      <i class="ri-facebook-fill"></i>
      {/* <i class="ri-twitter-fill"></i> */}
      {/* <i class="ri-instagram-fill"></i> */}
      {/* <i class="ri-pinterest-fill"></i> */}
      <i class="ri-telegram-fill"></i>
      <i class="ri-youtube-fill"></i>
   
     </div>
    </footer>
  );
};

export default Footer;
