import { Link } from "react-router-dom";
import styles from "./courseCard.module.css";
import { useTranslation } from "react-i18next";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";


const CourseCard = ({ course, text }) => {
const shareUrl = 'https://www.epolearn.com/'
  const { t } = useTranslation();
  return (
    <div className={styles.courseCard}>
      <div className={styles.courseCardImgContainer}>
      <img
        className={styles.courseCard__img}
        src={
          course.photopath
            ? course.photopath
            : "https://images.pexels.com/photos/256417/pexels-photo-256417.jpeg?auto=compress&cs=tinysrgb&w=600"
        }
        alt=""
      />
      </div>
      <div className={styles.courseCard__content}>
        {text === "Enroll" || text === "ምዝገባ" ? (
          <>
            <Link className="link" to={`/course/${course.id}`}>
              <h4 className={`${styles.courseCard__title} ${styles.courseCard__title_link}`}>
                {localStorage.getItem('lng')==="en" ? course.title : course.tigTitle}
              </h4>
            </Link>
            <p>
                {localStorage.getItem('lng')==="en" ? course.description : course.tigDescription}
            </p>
          </>
        ) : (
          <h4 className={styles.courseCard__title}>
            {localStorage.getItem('lng')==="en" ? course.name : course.tigName}
          </h4>
        )}
        <div className={styles.courseCard__footer}>
          {/* {course.registered > 0 ? (
            <div>
              <h6>
                Enrolled for {course.currentMonth}: {course.registered}/
                {course.total}
              </h6>

              <h6>Days remaining: {course.daysRemaining}</h6>
            </div>
          ) : null} */}
          <div className={styles.courseCard__share}>
          <FacebookShareButton url={shareUrl} quote={'Learn online using epolearn'} hashtag={'#goodLearning'}>
            <FacebookIcon size={24} round />
</FacebookShareButton>
{/* <FacebookMessengerShareButton url={shareUrl}>
            <FacebookMessengerIcon size={24} round />
</FacebookMessengerShareButton> */}
<TwitterShareButton url={shareUrl}>
            <TwitterIcon size={24} round />
</TwitterShareButton>
<WhatsappShareButton url={shareUrl}>
            <WhatsappIcon size={24} round />
</WhatsappShareButton>
{/* <EmailShareButton url={shareUrl}>
            <EmailIcon size={24} round />
</EmailShareButton> */}
<TelegramShareButton url={shareUrl}>
            <TelegramIcon size={24} round />
</TelegramShareButton>
          </div>
          <hr />
          <Link
            to={
              text === "Enroll" || text === "ምዝገባ"
                ? `/register/${course.id}`
                : `/courses/${course.id}`
            }
          >
            <button className={styles.courseCard__button}>
              {text === "Enroll" || text === "ምዝገባ" ? t("enroll") : t("list")}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
