import { useParams } from "react-router-dom";

import {useCourseDetails} from "../../ReactQueries/UseQueryHooks";
import styles from './registrationPage.module.css'
import { useTranslation } from "react-i18next";
import Register from "../../Components/Register/Register";

const RegistrationPage = ({title}) => {
    const id = useParams().id;
    const { t } = useTranslation();
    const {data, isLoading, error} = useCourseDetails(id);
    if(isLoading) return <h1>...</h1>;
    if(error) return <h1>Error: {error.message}</h1>;
    const {singleCourse} = data.data;
     return (
        <>
        <h5 className={styles.title}>{t('registerFor')} '{localStorage.getItem('lng')==='en'?singleCourse[0].title:singleCourse[0].tigTitle}'</h5>
        <div className={styles.courses_container}>
     <Register/>
        </div>
        </>
    );
    }

    export default RegistrationPage;