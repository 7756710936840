import { Link, useParams } from "react-router-dom";
import styles from "./courseInfo.module.css";
import { useTranslation } from "react-i18next";

const CourseInfo = () => {
  const { t } = useTranslation();

  const id = useParams().id;

  return (
    <div className={styles.container}>
      <div className={styles.infoDetail}>
        <p>{t("duration")}</p>
        <p>{t("month")}</p>
      </div>
      <Link className="link" to={`/register/${id}`}>
        <div className={styles.btn}>
          <button className={styles.enrollBtn}>{t("enroll")}</button>
        </div>
      </Link>
    </div>
  );
};

export default CourseInfo;
