import React, { useContext } from "react";
import { useParams } from "react-router-dom";

import {
  useCourseInfoContent,
  useCourseInfoWhatULearn,
} from "../../ReactQueries/UseQueryHooks";


import styles from "./course.module.css";
import WhatUlearn from "../../Components/WhatULearn/WhatULearn";
import CourseInfo from "../../Components/CourseInfo/CourseInfo";
import CourseContent from "../../Components/CourseContent/CourseContent";
import { LanguageContext } from "../../Contexts/LanguageContext";

const Course = () => {

  const {language} = useContext(LanguageContext);
  const id = useParams().id;
  const { isLoading, error, data } = useCourseInfoContent(id);
  const { isLoading: iL, error: er, data: dt } = useCourseInfoWhatULearn(id);
  if (isLoading || iL)
    return (
      <div>

          <span>Loading...</span>

      </div>
    );
  if (error || er) return null;
  const {
    data: { courseContent },
  } = data; //courseContent = data.data.courseContent
  const {
    data: { whatULearn },
  } = dt;

  console.log("whatULearn:", whatULearn);

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>
        {language === "en" ? whatULearn[0].courseTitle : whatULearn[0].tigCourseTitle}
      </h2>
      <div className={styles.items}>
        <div className={styles.leftItem}>
          <div className={styles.whatULearn}>
            <WhatUlearn whatULearn={whatULearn} />
          </div>
          <div className={styles.priceDetails}>
            <CourseInfo />
          </div>
        </div>
        <div className={styles.rightItem}>
          <CourseContent courseContent={courseContent} />
        </div>
      </div>
      <div className={styles.courses_bottom}></div>
    </div>
  );
};

export default Course;
