import React, { useContext } from "react";
import courseImg1 from "../../Assets/images/web-design.png";
import courseImg2 from "../../Assets/images/graphics-design.png";
import courseImg3 from "../../Assets/images/ui-ux.png";
import styles from "./courses.module.css";

import { Link } from "react-router-dom";
import CourseCard from "./CourseCard/CourseCard";

import { useTranslation } from "react-i18next";

const Courses = ({ courseCategories }) => {
  const { t } = useTranslation();
  return (
    <section>
      <div className={styles.container}>
        <h2>{t("courseCategories")}</h2>
        <div className={styles.courseTopLeft}>
          <p>
            {localStorage.getItem("lng") === "en"
              ? courseCategories.generalCourseDescription[0].engText
              : courseCategories.generalCourseDescription[0].tigText}
          </p>
          <div>
            <Link to="/categories" className="link">
              <button className={styles.btns}>{t("seeAll")}</button>
            </Link>
          </div>
        </div>
        <div className={styles.cardsContainer}>
        {courseCategories.courseCategories.map((course) => (
          <div key={course.id} style={{ marginBottom: "10px" }}>
            <CourseCard key={course.id} course={course} text={"List"} />
          </div>
        ))}
        </div>
      </div>
    </section>
  );
};

export default Courses;
