
import styles from './courseCategories.module.css'

import CourseCard from "../../Components/Courses/CourseCard/CourseCard";
import { useGetFetchQuery } from "../../ReactQueries/UseQryClient";
import { useTranslation } from "react-i18next";

const CourseCategories = ({title}) => {
  const {data} = useGetFetchQuery("courseCategories");
const {courseCategories} = data;
const {t} = useTranslation();
    return (
        <>
        <h2 className={styles.title}>{t('categories')}</h2>
        <div className={styles.coursecategories_container}>
       {courseCategories.map((courseCategory) => (
           <CourseCard course={courseCategory} key={courseCategory.id} text ={"List"} />
         ))}
        </div>
        </>
    );
    }

    export default CourseCategories;