import { Link } from "react-router-dom";
import { useContext } from "react";
import login from "../../Assets/images/login.png";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../Contexts/LanguageContext";
import styles from "./header.module.css";

const Header = ({ displaySearch }) => {


  const { i18n } = useTranslation();
//   const menuToggle = () => menuRef.current.classList.toggle("active__menu");
const { setLanguage } = useContext(LanguageContext);
  const changeLanguage = (lng) => {
    setLanguage(lng);
    localStorage.setItem("lng", lng);
    i18n.changeLanguage(lng);
  };
  return (
    <header className={styles.header}>
      {/* <Container> */}
        <div className={styles.headerItems}>
          <i
            class="ri-menu-4-line"
            style={{
              color: "black",
              display: displaySearch ? "block" : "none",
            }}
          ></i>
          <Link className="link" to="/">
            {" "}
            <h2 className={styles.headerLargeScreen}>
              <span style={{ color: "#089000" }}>Epo</span>
              <span style={{ borderBottom: "2px solid red" }}>Learn</span>
            </h2>{" "}
            <h2 className={styles.headerSmallScreen}>
              <span style={{ color: "#089000" }}>E</span>
              <span style={{ borderBottom: "2px solid red" }}>L</span>
            </h2>{" "}
          </Link>
          <input
            type="text"
            placeholder="Search"
            className={styles.searchTextBox}
            style={{ display: displaySearch ? "block" : "none" }}
          />
          <i
            class="ri-search-line"
            style={{
              color: "black",
              display: displaySearch ? "block" : "none",
            }}
          ></i>
          <div className={styles.languageContainer}>
            <h5 >
              <span className={styles.languageContainerItems}
                style={{
                  color: localStorage.getItem("lng") === "en" ? "red" : "black",
                }}
                onClick={() => {
                  changeLanguage("en");
                }}
              >
                English
              </span>{" "}
              |{" "}
              <span className={styles.languageContainerItems}
                style={{
                  color: localStorage.getItem("lng") === "tg" ? "red" : "black",
                }}
                onClick={() => {
                  changeLanguage("tg");
                }}
              >
                ትግርኛ
              </span>
            </h5>
            <span style={{marginLeft:"4px"}}>
              {" "}
              {/* <a className="text-decoration-none" href="https://eng.epolearn.com/login/index.php">Log in</a> */}
              <a className="link" href="https://eng.epolearn.com/login/index.php"> <img
                    src={login}
                    alt=""/></a>
            </span>
          </div>
        </div>
      {/* </Container> */}
    </header>
  );
};

export default Header;
