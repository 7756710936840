import React, { Fragment } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import Testimonials from "../../Components/Testimonials/Testimonials";
import Courses from "../../Components/Courses/Courses";
import styles from "./home.module.css";

const Home = () => {
  const { isLoading, error, data } = useQuery("randomFeaturedCourses", () =>
    axios.get(`${process.env.REACT_APP_SERVERPATH}/api/courses/random`)
  );
  const { isLoading:il, error:er, data:dt } = useQuery("courseCategories", () =>
  axios.get(`${process.env.REACT_APP_SERVERPATH}/api/categories`)
);

  if (isLoading || il)  return (
      <div>
        <span>Loading...</span>
      </div>
  );
  if (error || er) return <div style={{padding:"0 10px"}}>Error. Please check your internet connection.</div>;

  const { randomCourse } = data.data;

  return (
    <div className={styles.container}>
      <Testimonials featuredCourses = {randomCourse} />
      <Courses courseCategories={dt.data}/>
    </div>
  );
};

export default Home;
