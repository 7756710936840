import {  useContext} from "react";

import { useTranslation } from "react-i18next";


import styles from "./whatULearn.module.css";



const WhatUlearn = ({ whatULearn }) => {


  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <h4 style={{display: "flex", flexWrap:"wrap", justifyContent:"center", marginBottom:"10px"}}>
        {t("whatULearn")}
      </h4>
      <div style={{display:"flex"}}>
      <ul style={{display:"flex", flexDirection:"column"}}>
        {whatULearn.map((detail) => (
         <div style={{display:"flex"}} key={detail.id}>
            <span>&#10003;</span>
            <li>{localStorage.getItem("lng") === "en"?detail.whatULearn:detail.tigWhatULearn}</li>
            </div>
        ))}
          </ul>
      </div>
    </div>
  );
};

export default WhatUlearn;
