import React from 'react';
import Select from 'react-select';

const CustomSelect = ({ onChange, options, value, className, pHolder }) => {

    const defaultValue = (options, value) => {
        return options ? options.find(option => option.value === value) : "";
    };

    return (
        <div className={className}>
            <Select
            placeholder={pHolder}
            isSearchable={false}
                value={defaultValue(options, value)}
                onChange={value => {
                    onChange(value)

                }} options={options}
                isOptionDisabled={(option) => option.disabled} />
        </div>

    )
}

export default CustomSelect