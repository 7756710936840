import {  useFormik } from "formik";
// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import styles from "./register.module.css";
import CustomSelect from "../CustomSelect/CustomSelect";
import {
  useLearningMonths,
  useInsertLearners,
} from "../../ReactQueries/UseQueryHooks";
import { useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";


export default function Register() {
  const navigate = useNavigate();
 const { t } = useTranslation();
  const [dataIsSubmitting, setDataIsSubmitting] = useState(false);
  const [dataSubmitted, setDataSubmitted] = useState(false);
  const [redirectTime, setRedirectTime] = useState(10);
  const [learnerAlreadyRegistered, setLearnerAlreadyRegistered] =
    useState(false);
  const captchaRef = useRef();
  const id = useParams().id;
  const { isLoading, error, data } = useLearningMonths(id);
  const {
    mutate: insertLearner,
    isLoading: isMutating,
    error: mutationError,
  } = useInsertLearners();
  const validate = async (values) => {
    const errors = {};
    console.log("values:", values);
    if (!values.month || values.month === undefined) {
      errors.month = t('requiredMonth');
      
    }

    if (
      !values.firstName ||
      values.firstName.length < 3 ||
      values.firstName.length > 15 ||
      !/^[a-zA-Z]+$/.test(values.firstName)
    ) {
      errors.firstName = t('requiredFirstName');
    }

    if (
      !values.lastName ||
      values.lastName.length < 3 ||
      values.lastName.length > 15 ||
      !/^[a-zA-Z]+$/.test(values.lastName)
    ) {
      errors.lastName =  t('requiredLastName');
    }

    if (
      !values.email ||
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email =  t('requiredEmail');
    }
    if (!values.phoneNumber || !values.phoneNumber.match(/^\d{10}$/)) {
      errors.phoneNumber =  t('requiredPhone');;
    }

    return errors;
  };

  const getLearners = async (learnerInfo) => {
    const result = await axios.get(
      `${process.env.REACT_APP_SERVERPATH}/api/learners/getLearner`,
      { params: learnerInfo }
    );
    return result;
  };
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      month: "",
    },
    validate,
    onSubmit: async (values) => {
      const token = await captchaRef.current.executeAsync();

      captchaRef.current.reset();

      const Learner = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.phoneNumber,
        registeredForMonth: values.month.split(",")[0].trim(),
        registeredForYear: values.month.split(",")[1].trim(),
        courseId: id,
        token,
      };
      const LearnerInfo = {
        courseId: id,
        phone: values.phoneNumber,
        registeredForMonth: values.month.split(",")[0].trim(),
        registeredForYear: values.month.split(",")[1].trim(),
      };

      setDataIsSubmitting(true);
      setDataSubmitted(false);
      let learners = await getLearners(LearnerInfo);
      if (learners?.data?.learner[0].length > 0) {
        setLearnerAlreadyRegistered(true);
        setDataIsSubmitting(false);
        return;
      }

      insertLearner(Learner);
      if (isMutating) {
        setDataIsSubmitting(true);
        setDataSubmitted(false);
      } else {
        setDataIsSubmitting(false);
        setDataSubmitted(true);
        let b = 10;
        const a = setInterval(() => {
          setRedirectTime((prev) => prev - 1);
          b = b - 1;
          if (b === 0) {
            clearInterval(a);
            navigate("/");
          }
        }, 1000);
      }
    },
  });
  if (isLoading)  return (
  
      <div >
        Loading...
      </div>
   
  );
  if (error) return null;

  const { learningMonths } = data.data;
  const options = [];
  learningMonths[0].map((month) => {
    let optionObj = {
      value: `${month.monthNum}, ${month.yearNum}`,
      label: `${month.monthNum}, ${month.yearNum}`,
      disabled: false,
    };
    options.push(optionObj);
  });

  const registerForm = (
    <div>
      <form onSubmit={formik.handleSubmit} className={styles.registerForm}>
        <div className={styles.register_registerForm}>
          <CustomSelect
            className={styles.register_customSelect_input}
            onChange={(value) => {
              formik.setFieldValue("month", value.value);
            }}
            value={formik.values.month}
            options={options}
            pHolder= {t('monthPlaceHolder')}
          />
          {formik.touched.month && formik.errors.month ? (
            <div className={styles.register_error}>{formik.errors.month}</div>
          ) : null}

          <input
            id="firstName"
            name="firstName"
            type="text"
            className={styles.register_input}
            onChange={formik.handleChange}
            value={formik.values.firstName}
            placeholder= {t('firstNamePlaceHolder')}
          />
          {formik.touched.firstName && formik.errors.firstName ? (
            <div className={styles.register_error}>{formik.errors.firstName}</div>
          ) : null}
          <input
            id="lastName"
            name="lastName"
            type="text"
            className={styles.register_input}
            onChange={formik.handleChange}
            value={formik.values.lastName}
            placeholder= {t('lastNamePlaceHolder')}
          />
          {formik.touched.lastName && formik.errors.lastName ? (
            <div className={styles.register_error}>{formik.errors.lastName}</div>
          ) : null}

          <input
            id="email"
            name="email"
            type="email"
            className={`${styles.register_input} ${styles.register_email}`}
            onChange={formik.handleChange}
            value={formik.values.email}
            placeholder= {t('emailPlaceHolder')}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className={styles.register_error}>{formik.errors.email}</div>
          ) : null}

          <input
            id="phoneNumber"
            name="phoneNumber"
            type="text"
            className={styles.register_input}
            onChange={formik.handleChange}
            value={formik.values.phoneNumber}
            placeholder= {t('phonePlaceHolder')}
          />
          {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
            <span className={styles.register_error}>{formik.errors.phoneNumber}</span>
          ) : null}

          <div>
            <p className={styles.register_terms}>
            {t('agreement')}
              <a href="https://www.google.com" className={styles.register_terms_link}>
                Terms of Service
              </a>{" "}
              <br />
              and{" "}
              <a href="https://www.google.com" className={styles.register_terms_link}>
                Privacy Policy
              </a>
            </p>
          </div>
          <ReCAPTCHA
            // sitekey="6Lc8-RAjAAAAAIcLxLjthsU65iC2afL1NSEj3drR"
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            size="invisible"
            ref={captchaRef}
          />
          <button
            type="submit"
            className={styles.register_submit}
            disabled={dataIsSubmitting}
          >
            {dataIsSubmitting && (
              // me-3 means margin right (me=margin end) 3, 3 times the size of constant spacing defined in bootstrap
              <span>... </span>
            )}
            {t("register")}
          </button>
          {learnerAlreadyRegistered && (
            <p style={{ color: "red" }}>
              {t('otherPerson')} <br></br>{t('sameMonth')}<br></br>{t('changeMonthOrPhone')}{" "}
            </p>
          )}
        </div>
      </form>
    </div>
  );

  const dataSubmittedMessage = (
    <div className="container register_dataSubmitted">
      <h4>{t('thankyou')}</h4>
      <p>{t('weWillContact')}</p>
      <p>{t('redirect')} {redirectTime} {t('redirectSeconds')}</p>
    </div>
  );

  return <>{!dataSubmitted ? registerForm : dataSubmittedMessage}</>;
}
