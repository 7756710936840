import i18next from "i18next";
import { initReactI18next} from "react-i18next";

import en from "./en.json";
import tg from "./tg.json";

i18next.use(initReactI18next).init({
    debug: true,
    resources: {
      en: { translation: en },
      tg: { translation: tg },
    },
    lng: localStorage.getItem('lng') || "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

  export default i18next;