import axios from "axios";
import { useQuery, useMutation } from "react-query";

export const useCourseInfo2 = (id) =>
  useQuery("courseInfoData2", courseInfoContent(id));

// useCourseInfo2 doesn't work, but useCourseInfo does, don't know the difference

export function useCourseInfoContent(id) {
  const { data, error, isLoading } = useQuery(
    "courseInfoContent",
    () => courseInfoContent(id),
    {
      enabled: !!id,
    }
  );
  return { data, error, isLoading };
}
const courseInfoContent = (id) =>
  axios.get( `${process.env.REACT_APP_SERVERPATH}/api/courses/courseContent/${id}`);

export function useCourseInfoWhatULearn(id) {
  const { data, error, isLoading } = useQuery(
    "courseInfoWhatULearn",
    () => courseInfoWhatULearn(id),
    {
      enabled: !!id,
    }
  );
  return { data, error, isLoading };
}
const courseInfoWhatULearn = (id) =>
  axios.get( `${process.env.REACT_APP_SERVERPATH}/api/courses/whatULearn/${id}`);

export function useLearningMonths(id) {
  const { data, error, isLoading } = useQuery(
    "learningMonths",
    () => getLearningMonths(id),
    {
      enabled: !!id,
    }
  );
  return { data, error, isLoading };
}
const getLearningMonths = (id) =>
  axios.get( `${process.env.REACT_APP_SERVERPATH}/api/courses/learningMonths/${id}`);

export function useCourseDetails(id) {
  const { data, error, isLoading } = useQuery(
    "courseDetails",
    () => getCourseDetails(id),
    {
      enabled: !!id,
    }
  );
  return { data, error, isLoading };
}
const getCourseDetails = (id) =>
  axios.get( `${process.env.REACT_APP_SERVERPATH}/api/courses/${id}`);


  export function useInsertLearners() {
    return useMutation(insertLearners);

  }
  const insertLearners = (learner) =>
    axios.post( `${process.env.REACT_APP_SERVERPATH}/api/learners`, learner);


    export function useLearnerInfo(learnerInfo) {
      const { data, error, isLoading } = useQuery(
        "learnerInfo",
        () => getLearnerInfo(learnerInfo)
      );
      return { data, error, isLoading };
    }
    const getLearnerInfo = (id) =>
      axios.get( `${process.env.REACT_APP_SERVERPATH}learners/getLearner`);