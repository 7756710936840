import { useParams } from "react-router-dom";
import { useContext } from "react";
import CourseCard from "../../Components/Courses/CourseCard/CourseCard";
import styles from "./courses.module.css";
import { useQuery } from "react-query";
import { LanguageContext } from "../../Contexts/LanguageContext";
import axios from "axios";

const Courses = () => {
  const id = useParams().id;
  const {language} = useContext(LanguageContext);
  const { isLoading, error, data } = useQuery("coursesInCategory", () =>
    axios.get(
      `${process.env.REACT_APP_SERVERPATH}/api/courses/coursesInCat/${id}`
    )
  );
  console.log("lng data:", localStorage.getItem("lng"));
  if (isLoading)
    return (
      <div>
        <span>Loading...</span>
      </div>
    );
  if (error) return null;
  const { coursesWithCategory } = data.data;

  return (
    <>
      <h2 className={styles.title}>
        {language === "en"
          ? coursesWithCategory.catInfo[0].name
          : coursesWithCategory.catInfo[0].tigName}
      </h2>
      <div className={styles.coursesContainer}>
        {coursesWithCategory.courseInCat.map((course) => (
          <CourseCard course={course} key={course.id} text={"Enroll"} />
        ))}
      </div>
    </>
  );
};

export default Courses;
