import { useState, createContext } from "react";

export const LanguageContext = createContext();

 const LanguageProvider = (props) => {
    const [language, setLanguage] = useState();
    const values = { language, setLanguage };
    return (
        <LanguageContext.Provider value={values}>
        {props.children}
        </LanguageContext.Provider>
    );
    };

export default LanguageProvider;