import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import {useTranslation } from "react-i18next";



// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import styles from './courseContent.module.css'



export default function CourseContent({courseContent}) {


    const { t } = useTranslation();
    return (
        <div className={styles.courseContentContainer}>
        <h4>{t('courseContent')}</h4>
        <Accordion allowZeroExpanded={true} allowMultipleExpanded={true} style={{width:'100%'}}>
            {courseContent.map((chapter, index) =>
           
            <AccordionItem key={index} >
                <AccordionItemHeading>
                    <AccordionItemButton>
                        {t('chapter')} {index+1}: {localStorage.getItem("lng") === "en"?chapter.engChapterText:chapter.tigChapterText}
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    {chapter.chapterContent.map((content, index) =>
                    <p key={index} className={styles.contentFormat}>
                        {/* https://www.rapidtables.com/web/html/html-codes.html */}
                     <span>	&#9745;</span>   {localStorage.getItem("lng") === "en"?content.engContent:content.tigContent}
                    </p>
                    )
}
                </AccordionItemPanel>
            </AccordionItem>
            )
 }
        </Accordion>
        </div>
    );
}