import Slider from "react-slick";
import { Link } from "react-router-dom";
import img from "../../Assets/images/onlineEducation2.png";

import {useTranslation } from "react-i18next";
import styles from "./testimonials.module.css";

const Testimonials = ({featuredCourses}) => {

  const { t } = useTranslation();
  const settings = {
    infinite: true,
    dots: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToScroll: 1,
    pauseOnHover: true,
  };
  
  return (
    // <Suspense fallback={<div>Loading...</div>}>
      <div className={styles.container}>

              <div className={styles.testimonialWrapper}>
                <div className={styles.testimonialImg}>
                  <img
                    src={img}
                    alt=""
                  />
                </div>

                <div className={styles.testimonialContent}>
                    <h2 style={{marginBottom:"4px"}}>{t('courseHeading')}</h2>
<span>
                  <Slider {...settings}>
                    {featuredCourses
                      .map((course) => {
                        return  <div key={course.id}>
                            <div className={styles.single__testimonial}>
                              <h3 style={{marginBottom:"4px", fontWeight:"bold"}}>
                                {localStorage.getItem('lng')==="en"?course.title:course.tigTitle}
                              </h3>
                              <p>{localStorage.getItem('lng')==="en"?course.description:course.tigDescription}</p>


                                <Link to={`/course/${course.id}`}>
                                  <button className={styles.btns}>
                                    {t('select')}
                                  </button>
                                </Link>
                         
                            </div>
                          </div>
                      })}
                  </Slider>
                  </span>
                </div>
              </div>
      </div>
    // </Suspense>
  );
};

export default Testimonials;
